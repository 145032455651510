.table {
  width: 100%;
  display: grid;
  overflow: auto;

  grid-auto-rows: max-content;
  box-sizing: border-box;
  border: thin solid black;
}

.column {
  // text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.row {
  display: inline-block;
  &:nth-child(even) {
    background-color: grey;
  }
  &:nth-child(odd) {
    background-color: white;
  }
}

.row {
  display: contents;

  &:hover > * {
    background-color: white;
    filter: brightness(75%);
  }
}
