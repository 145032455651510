.button {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 8px;
  cursor: pointer;
  color: white;

  &:hover {
    filter: brightness(85%);
  }
  &:active {
    filter: brightness(75%);
  }
}

.plain {
  background-color: white;
}

.default {
  background-color: rgb(17, 114, 226);
}
