.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: brightness(25%) blur(10px);

  pointer-events: none;
  transition: 500ms opacity;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .modalContents {
    color: black;
    background-color: white;
    border-radius: 0.25em;
    display: flex;
    flex-direction: column;

    .modalHeader {
      padding: 0.25em 0.5em;
      border-bottom: thin solid black;
      display: flex;

      .headerContent {
        flex-grow: 1;
        text-align: left;
        font-weight: 700;
      }

      .headerCloseButton {
        line-height: 30px;
        margin-left: 0.5em;
        cursor: pointer;
      }
    }

    .modalBody {
      padding: 0.5em;
      flex-grow: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }

    .modalFooter {
      padding: 0.25em 0.5em;
      border-top: thin solid black;
    }
  }
}

.visible {
  opacity: 1;
  pointer-events: all;
}
