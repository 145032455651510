.layout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 15vw 1fr;

  .sidebar {
    height: 100%;
  }

  .content {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
